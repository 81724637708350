.dashboard-display {
    width: 100vw;
    height: 100vh;
    padding: 0;
    background-color: rgb(157, 163, 169);
 
};

.dashboard-display .dashboard-display-h1 {
    font-style: italic;
    text-align: center;
    color: #254350;
};
