.login-form {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: -webkit-fill-available;
    background-color: hsl(0, 0%, 0%);
    border-radius: 1%;
    min-width: 300px;
    min-height: 450px;
};

.login-form .login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #494b4d;
  border-radius: 10px;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 95px;
  padding-bottom: 95px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 1);
};

.login-form .login-form-container label {
  color: #fff;
  line-height: 2;
  font-size: 1em;
};

.login-form .login-form-container .login-form-title {
  color: #fff;
  /*font-family: */
  font-size: 1.7em;
};

.login-form .login-form-container .login-form-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
};

.login-form .login-form-container .login-form-button {
  background-color: #007bff;
  font-size: 1em;
  width: 40%;
  margin-left: 30%;
  margin-right: 40%;
  color: #fff;
  padding: 2% 5%;
  border: none;
  cursor: pointer;
};

.login-form .login-form-container .login-form-button:hover {
  background-color: #0056b3;
}

.login-form .login-form-container .login-form-error {
  color: red;
  margin-bottom: 10px;
};

.login-form .login-form-container .login-form-link {
  margin-top: 15px;
  text-decoration: none;
  color: #007bff;
};

.login-form .login-form-container .login-form-link:hover {
  text-decoration: underline;
};

.login-form .login-form-container .login-form-forgot-password {
  color: #007bff;
  margin-top: 25px;
  text-decoration: none;
  line-height: 2;
};

.login-form .login-form-container .login-form-forgot-password:hover {
  text-decoration: underline;
};

.login-form .login-form-container .login-form-signup {
  margin-top: 15px;
  text-decoration: none;
  color: #007bff;
};

.login-form .login-form-container .login-form-signup:hover {
  text-decoration: underline;
};

.login-form .login-form-container .login-form-social-media {
  padding: 20px;
};

.login-form .login-form-container .login-form-social-media-icon-image {
  padding-right: 10px;
};