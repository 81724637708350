.signup-form {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: -webkit-fill-available;
  background-color: hsl(0, 0%, 0%);
  border-radius: 1%;
  min-width: 300px;
  min-height: 700px;
}

.signup-form .signup-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #494b4d;
  border-radius: 10px;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 40px;
  padding-bottom: 40px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 1);
}

.signup-form .signup-form-container label {
  color: #fff;
  line-height: 2;
  font-size: 1em;
};

.signup-form .signup-form-container .signup-form-title {
  color: #fff;
  font-size: 1.7em;
}

.signup-form .signup-form-container .signup-form-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.signup-form .signup-form-container .signup-form-button {
  background-color: #007bff;
  font-size: 1em;
  width: 40%;
  margin-left: 30%;
  margin-right: 40%;
  color: #fff;
  padding: 2% 5%;
  border: none;
  cursor: pointer;
}

.signup-form .signup-form-container .signup-form-button:hover {
  background-color: #0056b3;
}

.signup-form .signup-form-container .signup-form-error {
  color: red;
  margin-bottom: 10px;
}

.signup-form .signup-form-container .signup-form-login {
  margin-top: 15px;
  text-decoration: none;
  color: #007bff;
}

.signup-form .signup-form-container .signup-form-login:hover {
  text-decoration: underline;
}

.signup-form .signup-form-container .signup-form-social-media {
  padding: 20px;
}

.signup-form .signup-form-container .signup-form-social-media-icon-image {
  padding-right: 10px;
}