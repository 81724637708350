.dash-navigation {
    background-color: #f8f9fa;
    width: 20vw;
    height: 100vh;
    border: 1px solid #000000;
}

.dash-navigation-h1 {
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-style: italic;
    text-align: center;
    color: #000000;
}