.dashboard-page {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
};

.dashboard-page .dashboard-page-h1 {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-style: italic;
    text-align: center;
}