.home-page {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
};

.home-page h1 {
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size: 2em;
    color: rgb(255, 255, 255);
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
};

.home-page .home-page-button {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.25em;
    width: 150px;
    height: 50px;
    margin-top: 20px;
    border-radius: 5px;
    border: 1px solid rgb(255, 255, 255);
};

.home-page .home-page-button:hover {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
};